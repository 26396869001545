import { render, staticRenderFns } from "./BasesFormFilesItem.vue?vue&type=template&id=4a368718&scoped=true"
import script from "./BasesFormFilesItem.vue?vue&type=script&lang=js"
export * from "./BasesFormFilesItem.vue?vue&type=script&lang=js"
import style0 from "./BasesFormFilesItem.vue?vue&type=style&index=0&id=4a368718&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a368718",
  null
  
)

export default component.exports