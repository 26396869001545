var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field"},[_c('div',{class:{ control: true, 'has-icons-right': _vm.hasAppend }},[_c('textarea',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.val),expression:"val"}],ref:"input",class:{
        textarea: true,
        'self-adjust': true,
        'self-adjust__appended': _vm.hasAppend,
        'self-adjust__appended--small': _vm.hasAppend && _vm.smallIcon,
        'self-adjust--transparent': _vm.transparent,
        'self-adjust__contained': _vm.contained,
        [`self-adjust--${_vm.size}`]: true,
      },domProps:{"value":(_vm.val)},on:{"input":function($event){if($event.target.composing)return;_vm.val=$event.target.value}}},'textarea',_vm.$attrs,false),_vm.inputListeners)),_vm._v(" "),(_vm.hasAppend)?_c('span',{class:[
        'icon is-right',
        `event-clickable${_vm.smallIcon ? '--small' : ''}`,
      ]},[_vm._t("append")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }