import { render, staticRenderFns } from "./BadgesCardDragDrop.vue?vue&type=template&id=535667a4&scoped=true"
import script from "./BadgesCardDragDrop.vue?vue&type=script&lang=js"
export * from "./BadgesCardDragDrop.vue?vue&type=script&lang=js"
import style0 from "./BadgesCardDragDrop.vue?vue&type=style&index=0&id=535667a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "535667a4",
  null
  
)

export default component.exports