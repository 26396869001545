var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expander"},[_c('div',{ref:"expander",class:{
      expander__trigger: true,
      'expander__trigger--slim': _vm.slim,
      [`align-${_vm.align}`]: true,
      active: _vm.isOpen,
      'before-border': !_vm.isOpen && _vm.customAccordion,
      'border-thick': _vm.thickBorder,
      'border-success': _vm.type === 'is-success',
      'border-danger': _vm.type === 'is-danger',
      'expander__trigger--pending': _vm.pendingExample,
      'is-light': _vm.isLight,
      'cursor-pointer': _vm.customAccordion,
      border: !_vm.type && _vm.customAccordion,
    },on:{"click":function($event){return _vm.toggleAccordion()}}},[_c('div',{ref:"check",staticClass:"expander__trigger__check",on:{"click":function($event){$event.stopPropagation();}}},[_vm._t("check")],2),_c('div',{staticClass:"expander__trigger__header"},[_vm._t("header")],2),_c('div',{staticClass:"expander__trigger__options"},[_vm._t("options")],2)]),_c('Transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open && _vm.customAccordion),expression:"open && customAccordion"}],class:_vm.pendingExample ? 'expander__body--pending' : 'expander__body'},[_vm._t("body")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }