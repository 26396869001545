var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UnnnicModal',{staticClass:"modal-save-changes-error",attrs:{"scheme":"aux-red-500","modalIcon":"warning","text":_vm.$t('router.modal_save_changes_error.title')},on:{"close":function($event){return _vm.$emit('close')}}},[_c('p',{domProps:{"innerHTML":_vm._s(
      _vm.$tc(
        'router.modal_save_changes_error.description',
        _vm.$store.state.Brain.tabsWithError.length,
        {
          tabs: _vm.tabs,
        },
      )
    )}})])
}
var staticRenderFns = []

export { render, staticRenderFns }