var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.single ? 'repository-card__single' : 'repository-card']},[_c('div',{staticClass:"repository-card__align-items",attrs:{"id":"tour-create_intelligence-step-0"}},[_c('div',{staticClass:"repository-card__big-badge-wrapper"},[_c(_vm.clickable ? 'router-link' : 'span',{tag:"Component",attrs:{"to":_vm.repositoryDetailsRouterParams}},[_c('div',{staticClass:"repository-card__big-badge"},[_c('CustomIcon',{class:{ 'repository-card__big-badge__icon': true, clickable: _vm.clickable },attrs:{"value":_vm.repositoryIcon,"size":"extra-large"}})],1)])],1),_c('div',{staticClass:"repository-card__details"},[_c('div',{staticClass:"repository-card__title"},[_c('span',{staticClass:"repository-card__title__bagde"},[_c(_vm.clickable ? 'router-link' : 'span',{tag:"Component",attrs:{"to":_vm.repositoryDetailsRouterParams}},[_c('CustomIcon',{class:{
                'repository-card__title__bagde__icon': true,
                clickable: _vm.clickable,
              },attrs:{"fillMobile":true,"value":_vm.repositoryIcon}})],1)],1),_c(_vm.clickable ? 'router-link' : 'span',{tag:"Component",attrs:{"to":_vm.repositoryDetailsRouterParams}},[_c('span',{staticClass:"repository-card__title__repository"},[_vm._v(_vm._s(_vm.$attrs.name))])])],1),_c('div',{staticClass:"repository-card__info-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('webapp.layout.created_by')))]),_c('strong',{staticClass:"repository-card__info-item__created-by"},[_vm._v(" "+_vm._s(_vm.getProfile(_vm.$attrs.owner__nickname).name || _vm.$attrs.owner__nickname)+" ")])]),_c('div',{staticClass:"repository-card__flags"},_vm._l((_vm.$attrs.available_languages),function(language){return _c('span',{key:language,class:{
            'repository-card__flags__flag': true,
            'repository-card__flags__flag--main': language == _vm.$attrs.language,
          }},[_c('LanguageBadge',{attrs:{"language":language,"main":language == _vm.$attrs.language}})],1)}),0),_c('div',{staticClass:"repository-card__categories"},_vm._l((_vm.repositoryCategoryRouterParams),function(category){return _c(_vm.clickable ? 'router-link' : 'span',{key:category.id,tag:"Component",attrs:{"to":{
            path: '/home',
            query: { category: category.id },
          }}},[_c('BTag',{staticClass:"repository-card__categories__category",attrs:{"color":"grey-light","rounded":"","size":"is-small"}},[_vm._v(_vm._s(category.name))])],1)}),1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }