export default {
  SET_TOKEN: 'SET_TOKEN',
  SET_ORG: 'SET_ORG',
  SET_PROJECT: 'SET_PROJECT',
  OPEN_LOGIN_MODAL: 'OPEN_LOGIN_MODAL',
  CLOSE_LOGIN_MODAL: 'CLOSE_LOGIN_MODAL',
  SET_PROFILE: 'SET_PROFILE',
  SET_NICKNAME_AUTHENTICATED: 'SET_NICKNAME_AUTHENTICATED',
  CACHE_FETCH: 'CACHE_FETCH',
  SET_USERNAME: 'SET_USERNAME',
};
