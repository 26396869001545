var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tagComputed,_vm._b({tag:"Component",class:[
    'unnnic-text',
    `unnnic-text--size-${_vm.size}`,
    `unnnic-text--line-height-${_vm.lineHeight}`,
    `unnnic-text--family-${_vm.family}`,
    `unnnic-text--weight-${_vm.weight}`,
    `unnnic-text--color-${_vm.color}`,
    _vm.marginTop ? `unnnic-text--margin-top-${_vm.marginTop}` : null,
    _vm.marginBottom ? `unnnic-text--margin-bottom-${_vm.marginBottom}` : null,
  ]},'Component',_vm.$attrs,false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }