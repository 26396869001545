var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UnnnicToolTip',{attrs:{"side":"top","text":_vm.failureMessage,"enabled":_vm.file.status === 'fail'}},[_c('section',{class:[
      'files-list__content__file',
      `files-list__content__file--status-${_vm.file.status}`,
      { 'files-list__content__file--compressed': _vm.compressed },
    ]},[_c('section',{staticClass:"files-list__content__file__icon"},[_c('UnnnicIcon',{staticClass:"files-list__content__file__icon__itself",attrs:{"icon":_vm.file.status === 'fail' ? 'warning' : _vm.icon,"size":_vm.compressed ? 'sm' : 'avatar-nano',"scheme":_vm.file.status === 'fail' ? 'feedback-red' : 'weni-600'}})],1),_c('header',{staticClass:"files-list__content__file__content"},[_c('p',{staticClass:"files-list__content__file__content__title"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('p',{staticClass:"files-list__content__file__content__status"},[(_vm.file.status === 'uploading')?[_vm._v(" "+_vm._s(_vm.$t('content_bases.files.status.uploading'))+" ")]:_vm._e()],2)]),_c('section',{staticClass:"files-list__content__file__actions"},[(_vm.downloading)?_c('UnnnicIcon',{staticClass:"files-list__content__file__actions__loading",attrs:{"icon":"cached","size":"sm","scheme":"neutral-cloudy"}}):(
          _vm.file.status === 'uploaded' && _vm.file.extension_file !== 'site'
        )?_c('UnnnicIcon',{staticClass:"files-list__content__file__actions__icon",attrs:{"icon":"download","size":"sm"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.download.apply(null, arguments)}}}):_vm._e(),(!_vm.file.uuid.startsWith('temp-'))?_c('UnnnicIcon',{staticClass:"files-list__content__file__actions__icon",attrs:{"icon":"delete","size":"sm"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove')}}}):_vm._e()],1),(_vm.file.status === 'uploading')?_c('section',{class:['files-list__content__file__status-bar', _vm.file.extension_file]},[_c('div',{staticClass:"files-list__content__file__status-bar__filled",style:({ width: `${_vm.file.progress * 100}%` })})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }