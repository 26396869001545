var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['highlighted', `highlighted-text--size-${_vm.size}`]},[_vm._l((_vm.entitiesBlocks),function(entity,i){return _c('div',{key:i,staticClass:"highlighted-entity"},[_c('span',{class:['highlighted-entity-before', `highlighted-text--size-${_vm.size}`]},[_vm._v(" "+_vm._s(entity.before)+" ")]),_c('UnnnicToolTip',{attrs:{"side":"top","text":entity.entity,"enabled":""}},[_c('span',{class:[
          'highlighted-entity-text',
          _vm.state ? 'active-entity' : 'inactive-entity',
          `highlighted-text--size-${_vm.size}`,
          _vm.colorOnly && entity.entity !== _vm.colorOnly
            ? 'entity-selected'
            : entity.colorClass,
          _vm.failed,
          entity.entity === _vm.highlighted ? 'highlighted-selected' : '',
        ]},[_vm._v(" "+_vm._s(entity.text)+" ")])])],1)}),_c('div',{staticClass:"highlighted-base"},[_vm._v(_vm._s(_vm.text))]),_c('div',{staticClass:"highlighted-text"},[_vm._v(_vm._s(_vm.text))])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }