var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BLoading',{attrs:{"active":_vm.loading}}),_c('BInput',{staticClass:"card-number__field",attrs:{"placeholder":_vm.$t('webapp.payment.info.card_name')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(!_vm.loading)?_c('Card',{class:{
      'card-number': true,
      'card-number__field': true,
      'card-number--focus': _vm.cardIsFocused,
    },attrs:{"options":{
      hidePostalCode: true,
      classes: {
        base: 'card-number__input',
        empty: 'card-number__input__empty',
      },
    },"stripe":"pk_test_XXXXXXXXXXXXXXXXXXXXXXXX"},on:{"focus":function($event){_vm.cardIsFocused = true},"blur":function($event){_vm.cardIsFocused = false},"change":_vm.onChange}}):_vm._e(),_c('div',{staticClass:"payment-buttons"},[(_vm.showBackButton)?_c('BButton',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(" "+_vm._s(_vm.$t('webapp.payment.info.back'))+" ")]):_vm._e(),_c('BButton',{attrs:{"type":"is-primary"}},[_vm._v(" "+_vm._s(_vm.$t('webapp.payment.info.save'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }