var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-layout"},[_c('div',{class:[
      _vm.collapse
        ? 'dashboard-layout__main-panel'
        : 'dashboard-layout__main-panel--collapsed',
      _vm.basesNewOrBasesEdit ? 'none' : null,
      `page--${_vm.$route.name}`,
    ]},[(!_vm.basesNewOrBasesEdit)?_c('SideBar',{on:{"collapse":function($event){return _vm.collapseHandle()}}}):_vm._e(),_c('RouterView')],1),(_vm.getFinalModal && _vm.getFinalMessage !== 'true')?_c('Tour',{attrs:{"stepCount":1,"name":"tutorial_button"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }