var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('UnnnicTable',{staticClass:"mt-4",attrs:{"items":_vm.orderedList},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('UnnnicTableRow',{attrs:{"headers":_vm.table.headers},scopedSlots:_vm._u([{key:"checkarea",fn:function(){return [_c('UnnnicCheckbox',{style:({ margin: '4px' }),attrs:{"value":_vm.generalValue},on:{"change":_vm.changeGeneralCheckbox}})]},proxy:true},{key:"edit",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('webapp.inbox.intent'))+" ")]),_c('UnnnicIconSvg',{attrs:{"size":"xs","icon":`sort-${_vm.sorts.intent}`,"scheme":_vm.sorts.intent === 'default' ? 'neutral-clean' : 'brand-weni-soft',"clickable":""},on:{"click":function($event){return _vm.sort('intent')}}})]},proxy:true},{key:"delete",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('webapp.inbox.confidence'))+" ")]),_c('UnnnicIconSvg',{attrs:{"size":"xs","icon":`sort-${_vm.sorts.confidence}`,"scheme":_vm.sorts.confidence === 'default'
                ? 'neutral-clean'
                : 'brand-weni-soft',"clickable":""},on:{"click":function($event){return _vm.sort('confidence')}}})]},proxy:true}])})]},proxy:true},{key:"item",fn:function({ item }){return [_c('UnnnicTableRow',{attrs:{"headers":_vm.table.headers},scopedSlots:_vm._u([{key:"checkarea",fn:function(){return [_c('UnnnicCheckbox',{style:({ margin: '4px' }),on:{"change":_vm.dispatchSelectedItems},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]},proxy:true},{key:"sentence",fn:function(){return [_c('div',{staticClass:"break-text example-accordion__sentence",attrs:{"title":item.sentence},on:{"mouseenter":function($event){return _vm.activeSentence(item)},"mouseleave":function($event){return _vm.inactiveSentence(item)},"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.editSentence(item.id)}}},[_c('UnnnicIconSvg',{staticClass:"mr-2",attrs:{"icon":`${
                _vm.open && _vm.selectedItem === item.id
                  ? 'arrow-button-down-1'
                  : 'arrow-button-right-1'
              }`,"scheme":"neutral-cleanest","size":"xs","clickable":""}}),_c('span',{staticClass:"example-accordion__tag"},[_vm._v("["+_vm._s(item.nlp_log.language)+"]")]),_c('HighlightedEntity',{ref:item.id,attrs:{"id":item.id,"text":item.text,"highlighted":item.highlighted,"entities":item.nlp_log.entities.other || [],"colorOnly":item.entitySelected,"state":_vm.isSentenceActive}}),(_vm.showIntents)?_c('span',{staticClass:"ml-4 intent-label"},[_vm._v(" "+_vm._s(_vm.$t('webapp.translate.intent') + ' ' + item.intent)+" ")]):_vm._e()],1)]},proxy:true},{key:"edit",fn:function(){return [_c('div',{style:({ textAlign: 'left' })},[_c('span',[_vm._v(_vm._s(item.nlp_log.intent.name))])])]},proxy:true},{key:"delete",fn:function(){return [_c('div',{style:({ textAlign: 'left' })},[_c('span',[_vm._v(_vm._s(item.confidence))])])]},proxy:true}],null,true)}),(_vm.open && item.id === _vm.selectedItem)?_c('LogAccordion',{attrs:{"nlp_log":item.nlp_log,"id":item.id,"text":item.text,"created_at":item.created_at,"version_name":item.version_name},on:{"event_nlp":function($event){_vm.nlp = $event},"event_addLog":function($event){return _vm.addLogStructure($event)},"event_removeLog":function($event){return _vm.removeLogStructure($event)}}}):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }