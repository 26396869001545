var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'files-area__container',
    `files-area__container--shape-${_vm.shape}`,
    {
      'files-area__container--active': _vm.files.data.length && _vm.isClientDragging,
    },
  ],on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.drop.apply(null, arguments)}}},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"browser-file-input",attrs:{"type":"file","multiple":""},on:{"change":_vm.drop}}),(!_vm.files.data.length && _vm.shape !== 'accordion')?_c('div',{class:['paste-area', { 'paste-area--active': _vm.isClientDragging }]},[_c('section',[_c('UnnnicIntelligenceText',{attrs:{"tag":"p","family":"secondary","color":"neutral-darkest","size":"body-lg","weight":"bold","marginBottom":"ant"}},[_vm._v(" "+_vm._s(_vm.$t('content_bases.files.upload_content'))+" ")]),_c('UnnnicIntelligenceText',{attrs:{"tag":"p","family":"secondary","color":"neutral-cloudy","size":"body-gt"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('content_bases.files.supported_files'))}})])],1),_c('UnnnicButton',{staticClass:"paste-area__button",attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.$refs['browser-file-input'].click()}}},[_vm._v(" "+_vm._s(_vm.$t('content_bases.files.browse_file'))+" ")])],1):_c('BasesFormGenericList',{attrs:{"shape":_vm.shape,"title":_vm.shape === 'accordion'
        ? _vm.$t('content_bases.tabs.files')
        : _vm.$t('content_bases.files.uploaded_files'),"description":_vm.removeHTML(_vm.$t('content_bases.files.supported_files')),"addText":_vm.$t('content_bases.files.browse_file'),"items":_vm.files},on:{"update:items":function($event){_vm.files=$event},"add":function($event){return _vm.$refs['browser-file-input'].click()},"remove":($event) =>
        _vm.openDeleteFileModal(
          $event.uuid,
          $event.created_file_name || '',
          $event.extension_file || '',
        ),"load-more":function($event){return _vm.$emit('load-more')}}}),(_vm.alert)?_c('UnnnicAlert',{key:_vm.alert.text,style:({ zIndex: 1 }),attrs:{"type":_vm.alert.type,"text":_vm.alert.text},on:{"close":function($event){_vm.alert = null}}}):_vm._e(),(_vm.modalDeleteFile)?_c('UnnnicModal',{staticClass:"delete-file-modal",attrs:{"text":_vm.$t('content_bases.files.delete_file.title'),"closeIcon":false,"persistent":""}},[_c('UnnnicIcon',{attrs:{"slot":"icon","icon":"error","size":"md","scheme":"aux-red-500"},slot:"icon"}),_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(
        _vm.$t('content_bases.files.delete_file.description', {
          name: _vm.modalDeleteFile.name,
        })
      )},slot:"message"}),_c('UnnnicButton',{staticClass:"create-repository__container__button",attrs:{"slot":"options","type":"tertiary"},on:{"click":function($event){_vm.modalDeleteFile = false}},slot:"options"},[_vm._v(" "+_vm._s(_vm.$t('content_bases.files.delete_file.cancel'))+" ")]),_c('UnnnicButton',{staticClass:"create-repository__container__button attention-button",attrs:{"slot":"options","type":"warning","loading":_vm.modalDeleteFile.status === 'deleting'},on:{"click":_vm.remove},slot:"options"},[_vm._v(" "+_vm._s(_vm.$t('content_bases.files.delete_file.delete'))+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }