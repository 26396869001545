import { render, staticRenderFns } from "./ExamplesPendingTraining.vue?vue&type=template&id=06725924&scoped=true"
import script from "./ExamplesPendingTraining.vue?vue&type=script&lang=js"
export * from "./ExamplesPendingTraining.vue?vue&type=script&lang=js"
import style0 from "./ExamplesPendingTraining.vue?vue&type=style&index=0&id=06725924&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06725924",
  null
  
)

export default component.exports