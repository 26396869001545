import { render, staticRenderFns } from "./RepositoryViewBase.vue?vue&type=template&id=3e5d6cc7&scoped=true"
import script from "./RepositoryViewBase.vue?vue&type=script&lang=js"
export * from "./RepositoryViewBase.vue?vue&type=script&lang=js"
import style0 from "./RepositoryViewBase.vue?vue&type=style&index=0&id=3e5d6cc7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e5d6cc7",
  null
  
)

export default component.exports