var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UnnnicModal',{attrs:{"showModal":_vm.openModal,"text":_vm.$t(
      _vm.hasIntegration
        ? 'webapp.home.remove_integrate_modal_title'
        : 'webapp.home.integrate_modal_title',
      {
        intelligence: _vm.repository.name,
      },
    ),"scheme":_vm.hasIntegration ? 'feedback-red' : 'feedback-yellow',"modalIcon":"alert-circle-1","closeIcon":false}},[_c('div',{attrs:{"slot":"message"},slot:"message"},[_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.hasIntegration
          ? _vm.$t('webapp.home.remove_integrate_modal_subtitle')
          : _vm.$t('webapp.home.integrate_modal_subtitle')
      )}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasIntegration),expression:"hasIntegration"}],staticClass:"integration-modal__field"},[_c('span',{staticClass:"integration-modal__field__label",domProps:{"innerHTML":_vm._s(
          _vm.$t('webapp.home.confirm_with_username', { username: _vm.getUsername })
        )}}),_c('UnnnicInput',{staticClass:"integration-modal__field__input",attrs:{"placeholder":_vm.$t('webapp.home.confirm_with_username_placeholder')},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)]),_c('UnnnicButton',{attrs:{"slot":"options","type":"tertiary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.dispatchCloseModal()}},slot:"options"},[_vm._v(" "+_vm._s(_vm.$t('webapp.home.cancel'))+" ")]),_c('UnnnicButton',{staticClass:"integration-modal__button",class:{
      'integration-modal__button__opacity':
        _vm.checkInputConfirm && _vm.hasIntegration,
    },attrs:{"slot":"options","type":"secondary","loading":_vm.loading,"disabled":_vm.hasIntegration && _vm.checkInputConfirm},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.hasIntegration
        ? _vm.dispatchRemoveIntegrateRepository()
        : _vm.dispatchIntegrateRepository()}},slot:"options"},[(_vm.hasIntegration)?_c('span',{staticClass:"integration-modal__button__txt"},[_vm._v(_vm._s(_vm.$t('webapp.home.confirm_remove_integrate')))]):_c('span',[_vm._v(_vm._s(_vm.$t('webapp.home.confirm_integrate')))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }