var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"translation"},[_c('div',{staticClass:"translation-text"},[_c('div',{staticClass:"columns is-mobile translation-text__field"},[_c('div',{staticClass:"column"},[_c('HighlightedText',{attrs:{"text":_vm.currentText,"entities":_vm.currentEntities,"highlighted":_vm.highlighted,"size":"large"}})],1),_c('div',{staticClass:"column is-narrow"},[_c('button',{ref:"showOriginal",class:{
            button: true,
            'is-small': true,
            'is-primary': !_vm.showingOriginal,
            'is-info': _vm.showingOriginal,
            'is-loading': _vm.loadingOriginal,
          },attrs:{"disabled":_vm.loadingOriginal},on:{"click":function($event){return _vm.toggleOriginal()}}},[(_vm.showingOriginal)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('webapp.translate.show_translated'))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('webapp.translate.show_original')))])])])])]),(_vm.entitiesList.length > 0)?_c('div',{staticClass:"translation-entities"},_vm._l((_vm.entitiesList),function(entity,i){return _c('EntityTag',{key:i,attrs:{"entityName":entity,"highlighted":_vm.highlighted === entity},nativeOn:{"mouseenter":function($event){$event.stopPropagation();_vm.highlighted = entity},"mouseleave":function($event){$event.stopPropagation();_vm.highlighted = null}}})}),1):_vm._e(),_c('div',{staticClass:"translation-infos level is-mobile"},[_c('div',{staticClass:"level-left translation-infos__container"},[_c('div',{staticClass:"level-item translation-infos__container__text"},[_c('strong',[_vm._v(_vm._s(_vm.$t('webapp.translate.translated_from'))+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("languageVerbose")(_vm.from_language))+" ")]),_c('Flag',{attrs:{"language":_vm.from_language}}),_c('strong',[_vm._v(" ")]),_c('strong',[_vm._v(_vm._s(_vm.$t('webapp.translate.to'))+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("languageVerbose")(_vm.language))+" ")]),_c('Flag',{attrs:{"language":_vm.language}})],1),_c('div',{staticClass:"level-item has-text-grey-light"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.created_at,'from'))+" ")])]),(
        _vm.repository.authorization && _vm.repository.authorization.can_contribute
      )?_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('a',{staticClass:"delete-icon",attrs:{"href":`#delete-translation-${_vm.id}`},on:{"click":function($event){$event.preventDefault();return _vm.deleteThisTranslation()}}},[_c('BIcon',{staticClass:"level-left translation-infos__container__icon",attrs:{"icon":"delete"}})],1)])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }