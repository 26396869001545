import { render, staticRenderFns } from "./BooleanInput.vue?vue&type=template&id=0628221a&scoped=true"
import script from "./BooleanInput.vue?vue&type=script&lang=js"
export * from "./BooleanInput.vue?vue&type=script&lang=js"
import style0 from "./BooleanInput.vue?vue&type=style&index=0&id=0628221a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0628221a",
  null
  
)

export default component.exports