<template>
  <div>
    <HomeRepositoryCard
      :repositoryDetail="intelligence"
      @dispatchShowModal="showModal($event)"
    />

    <ModalContainer
      :infoModal="modalInfo"
      :showModal="openModal"
      @closeModal="openModal = false"
    >
    </ModalContainer>
  </div>
</template>
<script>
import HomeRepositoryCard from '@/components/repository/home/HomeRepositoryCard';
import ModalContainer from '@/components/repository/home/ModalContainer';

export default {
  name: 'HomeIntelligenceFromOrg',
  components: {
    HomeRepositoryCard,
    ModalContainer,
  },
  props: {
    intelligence: Object,
  },
  data() {
    return {
      modalInfo: {},
      openModal: false,
    };
  },
  methods: {
    showModal(value) {
      this.openModal = true;
      this.modalInfo = { ...value };
    },
  },
};
</script>
