var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expander"},[_c('div',{ref:"expander",class:{
      expander__trigger: true,
      'expander__trigger--slim': _vm.slim,
      [`align-${_vm.align}`]: true,
      active: _vm.isOpen,
      'before-border': !_vm.isOpen && _vm.customAccordion,
      'is-light': _vm.isLight,
      'cursor-pointer': true,
    },on:{"click":function($event){return _vm.toggleAccordion()}}},[_c('div',{staticClass:"expander__trigger__header"},[_vm._t("header")],2),_c('div',{staticClass:"expander__trigger__icon"},[_vm._t("icon")],2),_c('div',{staticClass:"expander__trigger__options"},[_vm._t("options")],2)]),_c('Transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open && _vm.customAccordion),expression:"open && customAccordion"}],class:'expander__body'},[_vm._t("body")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }