var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BTooltip',{attrs:{"active":!_vm.disabled && _vm.helpText && _vm.helpText.length > 0,"label":_vm.helpText,"multilined":"","type":"is-dark","position":"is-bottom"}},[_c('div',{class:{
      'number-card': true,
      'number-card--active': !_vm.disabled && _vm.active,
      'number-card--hoverable':
        !_vm.disabled && (_vm.clickable || (_vm.helpText && _vm.helpText.length > 0)),
      'number-card--disabled': _vm.disabled,
      'number-card--clickable': _vm.clickable && !_vm.disabled,
      'number-card--clickable--inverted': !_vm.disabled && _vm.active && _vm.clickable,
    },on:{"click":_vm.onClick}},[_c('h1',{class:['has-text-centered', `number-card__title--size-${_vm.size}`]},[_vm._v(" "+_vm._s(_vm.count)+" ")]),_c('p',{class:['has-text-centered', `number-card__subtitle--size-${_vm.size}`]},[_vm._v(" "+_vm._s(_vm.label)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }