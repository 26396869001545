var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"feedback",staticClass:"feedback"},[_c('section',{staticClass:"feedback__thumbs"},[_c('UnnnicToolTip',{attrs:{"side":"top","text":_vm.$t('quick_test.feedback.liked'),"enabled":""}},[_c('UnnnicIcon',{attrs:{"icon":"thumb_up","scheme":"neutral-cloudy","size":"sm","clickable":"","filled":_vm.feedback.value === 'liked'},nativeOn:{"click":function($event){_vm.$emit('update:feedback', { ..._vm.feedback, value: 'liked' });
          _vm.$nextTick(_vm.sendFeedback);}}})],1),_c('UnnnicToolTip',{attrs:{"side":"top","text":_vm.$t('quick_test.feedback.disliked'),"enabled":""}},[_c('UnnnicIcon',{attrs:{"icon":"thumb_down","scheme":"neutral-cloudy","size":"sm","clickable":"","filled":_vm.feedback.value === 'disliked'},nativeOn:{"click":function($event){return _vm.$emit('update:feedback', {
            ..._vm.feedback,
            value: 'disliked',
            reason: null,
          })}}})],1)],1),(_vm.feedback.value === 'disliked' && _vm.feedback.reason !== 'close_reason')?_c('section',{ref:"reason-container",staticClass:"feedback__reason"},[_c('header',{staticClass:"feedback__reason__header"},[_c('UnnnicIntelligenceText',{attrs:{"color":"neutral-dark","family":"secondary","weight":"bold","size":"body-gt"}},[_vm._v(" "+_vm._s(_vm.$t('quick_test.rate_your_answer'))+" ")]),_c('UnnnicIcon',{attrs:{"icon":"close","size":"sm","scheme":"neutral-cloudy","clickable":""},on:{"click":function($event){_vm.$emit('update:feedback', { ..._vm.feedback, reason: 'close_reason' });
          _vm.$nextTick(_vm.sendFeedback);}}})],1),_c('section',{staticClass:"feedback__reason__options"},_vm._l((_vm.reasons),function(reason){return _c('UnnnicRadio',{key:reason.value,attrs:{"value":reason.value,"globalValue":_vm.feedback.reason,"size":"md"},on:{"change":function($event){return _vm.$emit('update:feedback', { ..._vm.feedback, reason: reason.value })}}},[_vm._v(" "+_vm._s(reason.text)+" ")])}),1),_c('UnnnicButton',{staticClass:"feedback__reason__button-send-feedback",attrs:{"type":"tertiary","size":"small","loading":_vm.sendingFeedback,"disabled":_vm.feedback.reason === null},on:{"click":_vm.sendFeedback}},[_vm._v(" "+_vm._s(_vm.$t('quick_test.send_feedback'))+" ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }