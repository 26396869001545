<template>
  <div class="bar">
    <span
      :style="{ width: `${progress}%` }"
      :class="type || 'is-normal'"
    />
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors.scss';

.bar {
  position: relative;
  display: block;
  width: 100%;
  background-color: $color-grey-light;
}

.bar span {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 25px;
}

.is-normal {
  background-color: $color-grey-dark;
}

.is-secondary {
  background-color: $color-secondary;
}

.is-primary {
  background-color: $color-primary;
}

.bar span:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background-size: 100%;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.603) 25%,
    rgba(0, 0, 0, 0) 25%,
    rgba(0, 0, 0, 0) 50%,
    rgba(255, 255, 255, 0.603) 50%,
    rgba(255, 255, 255, 0.603) 75%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0)
  );
  background-size: 30px 30px;
  opacity: 0.3;
  animation: progress-animation 0.5s infinite linear;
}

@-webkit-keyframes progress-animation {
  0% {
    background-position: 0 100%;
  }
  100% {
    background-position: 30px 100%;
  }
}
@keyframes progress-animation {
  0% {
    background-position: 0 100%;
  }
  100% {
    background-position: 30px 100%;
  }
}
</style>
