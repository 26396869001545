var render = function render(){var _vm=this,_c=_vm._self._c;return _c('img',{class:{
    'container-icon': true,
    [`container-icon--${_vm.size}`]: !!_vm.size,
    'container-icon--spin': _vm.spin,
    'set-fill': true,
    'set-fill-mobile': _vm.fillMobile,
    'set-fill-primary': _vm.fillPrimary,
  },attrs:{"src":_vm.svg,"svg-inline":""}})
}
var staticRenderFns = []

export { render, staticRenderFns }