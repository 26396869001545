var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edit-sentence"},[_c('form',[_c('div',{staticClass:"columns edit-sentence__wrapper"},[_c('div',{staticClass:"column is-7"},[_c('BField',{attrs:{"errors":_vm.errors.text || _vm.errors.language,"label":_vm.$t('webapp.example.sentence')}},[_c('ExampleTextWithHighlightedEntitiesInput',{ref:"textInput",attrs:{"entities":_vm.allEntities,"placeholder":_vm.$t('webapp.example.enter_sentence'),"size":"normal"},on:{"textSelected":function($event){return _vm.setTextSelected($event)},"entityEdited":function($event){return _vm.onEditEntity($event)},"entityAdded":function($event){return _vm.onEntityAdded()}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)],1),_c('div',{staticClass:"column is-5"},[_c('BField',{attrs:{"message":_vm.errors.non_field_errors,"type":{
            'is-danger':
              _vm.errors.non_field_errors && _vm.errors.non_field_errors.length > 0,
          },"label":_vm.$t('webapp.example.intent')}},[_c('BAutocomplete',{attrs:{"data":_vm.filterIntents,"placeholder":_vm.$t('webapp.example.intent'),"dropdownPosition":"bottom","openOnFocus":""},on:{"input":function($event){_vm.intent = _vm.intentFormatters(_vm.intent)}},model:{value:(_vm.intent),callback:function ($$v) {_vm.intent=$$v},expression:"intent"}})],1)],1)]),_c('div',{staticClass:"edit-sentence__wrapper"},[_c('div',{staticClass:"edit-sentence__input__wrapper"},[_vm._l((_vm.entitiesToEdit),function(entity,index){return _c('div',{key:`entity-${index}`,staticClass:"edit-sentence__input"},[_c('BField',[_c('span',{staticClass:"edit-sentence__input__label",attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(
                _vm.$t('webapp.example.text_is', {
                  text: _vm.highlightedText(entity),
                })
              )},slot:"label"}),_c('BAutocomplete',{staticClass:"edit-sentence-input",attrs:{"data":_vm.filterEntities(index, false),"placeholder":_vm.$t('webapp.example.entity'),"dropdownPosition":"bottom","iconRight":"close","iconRightClickable":"","openOnFocus":""},on:{"input":function($event){_vm.entitiesToEdit[index].entity = _vm.intentFormatters(entity.entity)},"icon-right-click":function($event){return _vm.removeEntity(entity, index)}},model:{value:(entity.entity),callback:function ($$v) {_vm.$set(entity, "entity", $$v)},expression:"entity.entity"}})],1)],1)}),_vm._l((_vm.pendingEntities),function(entity,index){return _c('div',{key:`pending-entity-${index}`,staticClass:"edit-sentence__input"},[_c('BField',[_c('span',{staticClass:"edit-sentence__input__label",attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(
                _vm.$t('webapp.example.text_is', {
                  text: _vm.highlightedText(entity),
                })
              )},slot:"label"}),_c('BAutocomplete',{staticClass:"edit-sentence-input",attrs:{"data":_vm.filterEntities(index, true),"customFormatter":_vm.intentFormatters,"placeholder":_vm.$t('webapp.example.entity'),"dropdownPosition":"bottom","iconRight":"close","iconRightClickable":"","openOnFocus":""},on:{"input":function($event){_vm.pendingEntities[index].entity = _vm.intentFormatters(
                  entity.entity,
                )},"select":function($event){return _vm.elevateToEntity(entity, index)},"icon-right-click":function($event){return _vm.removePendingEntity(entity, index)}},model:{value:(entity.entity),callback:function ($$v) {_vm.$set(entity, "entity", $$v)},expression:"entity.entity"}})],1)],1)})],2),_c('BField',{attrs:{"message":_vm.errors.entities,"type":"is-danger"}})],1),_c('div',{staticClass:"edit-sentence__btn-wrapper"},[_c('BButton',{attrs:{"disabled":_vm.textSelected === null,"rounded":"","type":"is-primary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addPendingEntity.apply(null, arguments)}}},[_c('span',{staticClass:"edit-sentence__add-entity-button-text"},[_vm._v(_vm._s(_vm.entityButtonText)+" ")])]),_c('div',[_c('BButton',{staticClass:"edit-sentence__btn-wrapper__cancelButton",on:{"click":_vm.cancelEditSentence}},[_vm._v(" "+_vm._s(_vm.$t('webapp.trainings.cancel_button'))+" ")]),_c('BButton',{staticClass:"edit-sentence__btn-wrapper__saveButton",attrs:{"disabled":!_vm.isValid || _vm.submitting,"tooltipHover":!_vm.isValid ? _vm.validationErrors : null,"loading":_vm.submitting},on:{"click":_vm.onSubmit}},[(!_vm.submitting)?_vm._t("default",function(){return [_vm._v(_vm._s(_vm.$t('webapp.trainings.save_button')))]}):_vm._e()],2)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }