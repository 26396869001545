var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dropzone",class:{
    'drag-area__dropzone': true,
    'drag-area__dropzone__is-dragover': _vm.isDragging,
    'drag-area__dropzone__has-error': _vm.hasError,
  },on:{"dragenter":_vm.dragenter,"dragover":_vm.dragover,"dragleave":_vm.dragleave,"dragend":_vm.dragend,"drop":_vm.drop}},[(_vm.localList.length === 0)?_c('div',[_c('UnnnicIconSvg',{staticClass:"drag-area__dropzone__icon",attrs:{"icon":"study-light-idea-1","scheme":_vm.hasError ? 'feedback-red' : 'neutral-cloudy',"size":"xl"}}),_c('div',{staticClass:"drag-area__dropzone__content"},[_c('span',{staticClass:"drag-area__dropzone__content__title"},[_vm._v(" "+_vm._s(_vm.$t('webapp.home.drag_new'))+" ")])])],1):_vm._e(),_c('Draggable',{staticClass:"badges-card__wrapper",attrs:{"sort":false,"data-id-attr":_vm.identifier,"move":_vm.onMove,"group":"entities"},on:{"change":_vm.onChange},model:{value:(_vm.localList),callback:function ($$v) {_vm.localList=$$v},expression:"localList"}},_vm._l((_vm.localList),function(item,i){return _c('EntityTag',{key:i,class:[
        'badges-card__wrapper__badge',
        `badges-card__wrapper__badge--moving`,
      ],attrs:{"entityName":item.value,"closable":_vm.edit},on:{"close":function($event){return _vm.close(item)}},nativeOn:{"click":function($event){return _vm.goToEntity(item)}}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }