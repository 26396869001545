var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"summary-information"},[_c('div',{staticClass:"summary-information__info"},[_c('UnnnicCard',{attrs:{"type":"title","title":_vm.$t('webapp.summary.general_information'),"hasInformationIcon":false,"icon":"gauge-dashboard-2","scheme":"aux-purple"}}),_c('div',{staticClass:"summary-information__info__container"},[_c('UnnnicCardNumber',{staticClass:"summary-information__info__container__item--clickable",attrs:{"description":_vm.$tc(
            'webapp.summary.information_sentences',
            _vm.getCurrentRepository.examples__count,
          ),"number":_vm.getCurrentRepository.examples__count,"clickable":"","fullSize":""},nativeOn:{"click":function($event){return _vm.navigateToSentences.apply(null, arguments)}}}),_c('UnnnicCardNumber',{staticClass:"summary-information__info__container__item--clickable",attrs:{"description":_vm.$tc(
            'webapp.summary.information_intents',
            _vm.getCurrentRepository.intents_list.length,
          ),"number":_vm.getCurrentRepository.intents_list.length,"clickable":"","fullSize":""},nativeOn:{"click":function($event){return _vm.scrollToIntent.apply(null, arguments)}}}),_c('UnnnicCardNumber',{staticClass:"summary-information__info__container__item--clickable",attrs:{"description":_vm.$tc(
            'webapp.summary.information_entities',
            _vm.getCurrentRepository.entities.length,
          ),"number":_vm.getCurrentRepository.entities.length,"clickable":"","fullSize":""},nativeOn:{"click":function($event){return _vm.scrollToEntity.apply(null, arguments)}}}),_c('UnnnicToolTip',{attrs:{"side":"bottom","text":_vm.languagesList,"enabled":"","maxWidth":"15rem"}},[_c('UnnnicCardNumber',{attrs:{"description":_vm.$tc(
              'webapp.summary.information_language',
              _vm.getCurrentRepository.available_languages.length,
            ),"number":_vm.getCurrentRepository.available_languages.length,"fullSize":""}})],1),_c('UnnnicToolTip',{attrs:{"side":"bottom","text":_vm.contributorsList,"enabled":"","maxWidth":"15rem"}},[_c('UnnnicCardNumber',{attrs:{"description":_vm.$tc(
              'webapp.summary.information_contributors',
              _vm.getCurrentRepository.authorizations.count,
            ),"number":_vm.getCurrentRepository.authorizations.count,"fullSize":""}})],1),_c('UnnnicCardNumber',{attrs:{"description":_vm.$tc(
            'webapp.summary.information_integrations',
            _vm.getCurrentRepository.count_authorizations,
          ),"number":_vm.getCurrentRepository.count_authorizations,"fullSize":""}})],1)],1),_c('IntelligenceForce')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }