var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-navigation"},[_c('section',{staticClass:"tab-navigation__container"},[_c('div',{staticClass:"tab-navigation__container__content"},[_c('UnnnicCard',{class:{
          'tab-navigation__container__content--selected': _vm.currentTab === 0,
        },attrs:{"clickable":"","title":_vm.$t('webapp.intelligences_lib.tab_community_title'),"type":"default","description":_vm.$t('webapp.intelligences_lib.tab_community_subtitle')},nativeOn:{"click":function($event){return _vm.handleChangeTag(0)}}})],1),_c('div',{staticClass:"tab-navigation__container__content"},[_c('UnnnicCard',{class:{
          'tab-navigation__container__content--selected': _vm.currentTab === 1,
        },attrs:{"clickable":"","title":_vm.$t('webapp.intelligences_lib.tab_project_title'),"type":"default","description":_vm.$t('webapp.intelligences_lib.tab_project_subtitle', {
            project: 'Mississipi',
          })},nativeOn:{"click":function($event){return _vm.handleChangeTag(1)}}})],1),_c('div',{staticClass:"tab-navigation__container__content"},[_c('UnnnicCard',{class:{
          'tab-navigation__container__content--selected': _vm.currentTab === 2,
        },attrs:{"clickable":"","title":_vm.$t('webapp.intelligences_lib.tab_org_title'),"type":"default","description":_vm.$t('webapp.intelligences_lib.tab_org_subtitle', { org: 'Weni' })},nativeOn:{"click":function($event){return _vm.handleChangeTag(2)}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }