var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field"},[_c('div',{staticClass:"example-txt-w-highlighted-entities"},[_c('div',{class:{
        'example-txt-w-highlighted-entities__input-wrapper': true,
        'example-txt-w-highlighted-entities__transparent': _vm.transparent,
      }},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icons-right is-family-primary"},[_vm._l((_vm.entitiesBlocks),function(entity,i){return _c('div',{key:i,class:_vm.entityClassAttr},[_c('span',{class:['example-txt-w-highlighted-entities__entity__before']},[_vm._v(_vm._s(entity.before))]),_c('span',{class:[
                entity.colorClass,
                'example-txt-w-highlighted-entities__entity__text',
              ]},[_vm._v(_vm._s(entity.text))])])}),_c('SelfAdjustInput',_vm._b({ref:"input",attrs:{"smallIcon":_vm.smallIcon,"transparent":""},on:{"select":_vm.emitTextSelected,"blur":function($event){return _vm.$emit('blur')},"focus":function($event){return _vm.$emit('focus')},"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.emitTextSelected.apply(null, arguments)},"keyup":[function($event){$event.stopPropagation();$event.preventDefault();return _vm.emitTextSelected.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit()}]},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}},'SelfAdjustInput',_vm.$attrs,false),[(_vm.hasAppend)?_c('span',{attrs:{"slot":"append"},slot:"append"},[_vm._t("append")],2):_vm._e()])],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }